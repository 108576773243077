.nav-btn.active {
  color: #3b82f6;
}

#navbar-logo {
  height: 6rem;
}

#footer-logo {
  height: 4rem;
}

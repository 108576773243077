.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* @import url("https://fonts.googleapis.com/css?family=Encode+Sans"); */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal__backdrop {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modal__container {
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  margin: 50px auto;
  padding: 15px;
  width: 560px;
}

:root {
  --hl-key: #98ceeb50;
  --hl-date: #5afaffff;
  --hl-num: #7cff84ff;
  --hl-prop: #d703fc50;
  --hl-non: #ffffff00;
  --fnt-key: #000000ff;
  --fnt-date: #000000ff;
  --fnt-num: #000000ff;
  --fnt-prop: #000000ff;
  --fnt-non: #a2a2a2ff;
  --hlpos-ot: #ffffff00;
  --fntpos-ot: #000000ff;
  --hlpos-jj: #ffffff00;
  --fntpos-jj: #000000ff;
  --hlpos-nn: #ffffff00;
  --fntpos-nn: #000000ff;
  --hlpos-vb: #ffffff00;
  --fntpos-vb: #000000ff;


  --text-hl-key: #ffffff;
  --text-hl-date: #ffffff;
  --text-hl-num: #ffffff;
  --text-hl-prop: #ffffff;
  --text-hl-non: #ffffff00;
  --text-fnt-key: #000000ff;
  --text-fnt-date: #000000ff;
  --text-fnt-num: #000000ff;
  --text-fnt-prop: #000000ff;
  --text-fnt-non: #dcdcdc;
  --text-hlpos-ot: #ffffff00;
  --text-fntpos-ot: #000000ff;
  --text-hlpos-jj: #ffffff00;
  --text-fntpos-jj: #000000ff;
  --text-hlpos-nn: #ffffff00;
  --text-fntpos-nn: #000000ff;
  --text-hlpos-vb: #ffffff00;
  --text-fntpos-vb: #000000ff;
}

.readsyon {
  border-radius: 5px !important;
  padding: 2px !important;
}
.readsyon.readsyNum.hl {
  background-color: var(--hl-num) !important;
}

.readsyon.readsyDate.hl {
  background-color: var(--hl-date) !important;
}

.readsyon.readsyKey.hl {
  background-color: var(--hl-key) !important;
}

.readsyon.readsyProp.hl {
  background-color: var(--hl-prop) !important;
}

.readsyon.readsyNon.hl {
  background-color: var(--hl-non) !important;
}

.readsyon.readsyNum.fnt {
  color: var(--fnt-num) !important;
}

.readsyon.readsyDate.fnt {
  color: var(--fnt-date) !important;
}

.readsyon.readsyKey.fnt {
  color: var(--fnt-key) !important;
}

.readsyon.readsyProp.fnt {
  color: var(--fnt-prop) !important;
}

.readsyon.readsyNon.fnt {
  color: var(--fnt-non) !important;
}

.readsyon.OT.hlpos {
  background-color: var(--hlpos-cd) !important;
}
.readsyon.OT.fntpos {
  color: var(--fntpos-cd) !important;
}

.readsyon.JJ.hlpos {
  background-color: var(--hlpos-jj) !important;
}
.readsyon.JJ.fntpos {
  color: var(--fntpos-jj) !important;
}

.readsyon.NN.hlpos {
  background-color: var(--hlpos-nn) !important;
}
.readsyon.NN.fntpos {
  color: var(--fntpos-nn) !important;
}

.readsyon.VB.hlpos {
  background-color: var(--hlpos-vb) !important;
}
.readsyon.VB.fntpos {
  color: var(--fntpos-vb) !important;
}

.readsyon.textreadsyNum.hl {
  background-color: var(--text-hl-num) !important;
}

.readsyon.textreadsyDate.hl {
  background-color: var(--text-hl-date) !important;
}

.readsyon.textreadsyKey.hl {
  background-color: var(--text-hl-key) !important;
}

.readsyon.textreadsyProp.hl {
  background-color: var(--text-hl-prop) !important;
}

.readsyon.textreadsyNon.hl {
  background-color: var(--text-hl-non) !important;
}

.readsyon.textreadsyNum.fnt {
  color: var(--text-fnt-num) !important;
}

.readsyon.textreadsyDate.fnt {
  color: var(--text-fnt-date) !important;
}

.readsyon.textreadsyKey.fnt {
  color: var(--text-fnt-key) !important;
}

.readsyon.textreadsyProp.fnt {
  color: var(--text-fnt-prop) !important;
}

.readsyon.textreadsyNon.fnt {
  color: var(--text-fnt-non) !important;
}

.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  border: gray 1px solid;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.readsyon:hover .tooltiptext {
  visibility: visible;
  transform: translateY(-3rem);
}

.wrapper {
  margin: 0 auto;
}

.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  cursor: pointer;
  display: flex;

  &::after {
    content: "";
    width: 0;
    height: 0;
  }

  &:hover,
  &.open {
  }

  &.open {
    &::after {
      content: "";
    }
  }
}

.accordion-content {
  padding: 1em 1.5em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
